const $_PGW = {
  enabled: true,
  layoutMode: 'column',
  theme: 'dark',
  hideCloseButton: true,
  cssVars: {
    '--pgw-font-family': 'Noto Sans',
    '--pgw-background-primary': '#28292B',
    '--pgw-surface-primary': '#28292B',
    '--pgw-surface-secondary': '#323437',
    '--pgw-surface-secondaryHover': '#3C3F42',
    '--pgw-surface-accent': '#213A47',
    '--pgw-surface-accentHover': '#115162',
    '--pgw-surface-buttonPrimary': 'linear-gradient(270deg, #1CF7C1 0%, #0CC2E7 100%), #00FFC8',
    '--pgw-surface-buttonPrimaryHover': 'linear-gradient(270deg, #52FFD4 0%, #44DFFF 100%), #00E2B1',
    '--pgw-surface-buttonPrimaryPress': 'linear-gradient(270deg, #24C9A0 0%, #15A1BD 100%), #01A884',
    '--pgw-surface-buttonPrimaryDisabled': 'linear-gradient(270deg, #156652 0%, #0E5D6D 100%), #4F5157',
    '--pgw-surface-buttonSecondary': '#F5FDFD',
    '--pgw-surface-buttonSecondaryHover': '#D8F0EF',
    '--pgw-surface-buttonSecondaryPress': '#A3DCD9',
    '--pgw-surface-buttonSecondaryDisabled': '#506362',
    '--pgw-surface-buttonTertiary': '#3C3F42',
    '--pgw-surface-buttonTertiaryHover': '#4F5157',
    '--pgw-surface-buttonTertiaryPress': '#4F5157',
    '--pgw-surface-buttonTertiaryDisabled': '#4F5157',
    '--pgw-surface-inputPrimary': '#323437',
    '--pgw-surface-inputSecondary': '#28292B',
    '--pgw-surface-inputTertiary': '#3C3F42',
    '--pgw-surface-tab': '#323437',
    '--pgw-surface-tabHover': '#6C6F76',
    '--pgw-surface-tabPress': '#AFB4BE',
    '--pgw-surface-negative': '#E8544C',
    '--pgw-surface-positiveAlpha': '#64BA621A',
    '--pgw-surface-skeleton': '#323437',
    '--pgw-text-primary': '#F6F7F9',
    '--pgw-text-secondary': '#C8CCD3',
    '--pgw-text-tertiary': '#6E727A',
    '--pgw-text-button': '#323437', // will be replaced by '--pgw-text-buttonPrimary, please keep both
    '--pgw-text-buttonDisabled': '#3C3F42', // will be replaced by '--pgw-text-buttonPrimaryDisabled, please keep both
    '--pgw-text-buttonPrimary': '#323437',
    '--pgw-text-buttonPrimaryDisabled': '#3C3F42',
    '--pgw-text-buttonSecondary': '#323437',
    '--pgw-text-buttonSecondaryDisabled': '#3C3F42',
    '--pgw-text-positive': '#64BA62',
    '--pgw-text-warning': '#FCA209',
    '--pgw-text-negative': '#E8544C',
    '--pgw-text-constant': '#FFF',
    '--pgw-icon-primary': '#F6F7F9',
    '--pgw-icon-secondary': '#979DA8',
    '--pgw-icon-tertiary': '#4F5157',
    '--pgw-icon-button': '#323437',
    '--pgw-icon-buttonDisabled': '#4F5157',
    '--pgw-icon-buttonPrimary': '#64BA62', // will be replaced by '--pgw-icon-buttonPrimary, please keep both
    '--pgw-icon-buttonDisabledPrimary': '#FCA209', // will be replaced by '--pgw-icon-buttonPrimaryDisabled, please keep both
    '--pgw-icon-buttonPrimaryDisabled': '#FCA209',
    '--pgw-icon-buttonSecondary': '#000000',
    '--pgw-icon-positive': '#64BA62',
    '--pgw-icon-warning': '#FCA209',
    '--pgw-icon-negative': '#E8544C',
    '--pgw-stroke-secondary': '#46484D',
    '--pgw-stroke-secondaryHover': '#46484D',
    '--pgw-stroke-inputPrimary': '#4F5157',
    '--pgw-stroke-inputSecondary': '#333538',
    '--pgw-stroke-accent': '#007F97',
    '--pgw-stroke-accentHover': '#0095B0',
    '--pgw-stroke-positive': '#64BA62',
    '--pgw-stroke-positiveAlpha': 'rgba(100, 186, 98, 0.30)',
    '--pgw-stroke-warning': '#FCA209',
    '--pgw-stroke-negative': '#E8544C',
    '--pgw-divider-primary': '#4F5157',

    '--pgw-border-radius-s': '4px',
    '--pgw-border-radius-m': '8px',
    '--pgw-border-radius-l': '12px',
    '--pgw-border-radius-xl': '16px',
  },
};
export { $_PGW };
