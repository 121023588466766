(function(){
  'use strict';

  const directive = { name: 'datepickerInterconnection' };
  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link(scope, element, attrs){
      import('jquery').then(($) => {
        window.jQuery = $;

        const config = scope.$eval(attrs[directive.name]);
        let targetInput, initialOption, targetOption;

        if (!config) return false;

        if (config.hasOwnProperty('max')){
          targetInput = $(config.max);
          initialOption = 'maxDate';
          targetOption = 'minDate';
        }

        if (config.hasOwnProperty('min')){
          targetInput = $(config.min);
          initialOption = 'minDate';
          targetOption = 'maxDate';

          $timeout(() => {
            $(element).datepicker('option', targetOption, new Date($(element).attr('data-max-date')));
          });
        }

        $timeout(() => {
          $(element).datepicker('option', initialOption, new Date(targetInput[0].value));
        });

        scope.updateInterconnection = function(){
          targetInput.datepicker('option', targetOption, new Date(element[0].value));
        }
      });
    }

    return {
      restrict: 'A',
      link: link,
    }
  }

  app.directive(directive.name, controller)
})();
