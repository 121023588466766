(function () {
  'use strict';

  const component = { name: 'lindaProfilePopup' };

  controller.$inject = ['$scope'];

  function controller($scope) {}

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
