import { $_PGW } from './pgw.mjs';
var $_CONFIG = $_CONFIG || {};

$_CONFIG.scripts = $_CONFIG.scripts || []; // масив скриптів, які будуть додані в дом
$_CONFIG.domain = 'https://burancasino.devv2.space'; // домен апі (рекомендовано залишати пустим)
$_CONFIG.siteDomain = 'https://burancasino.com';
$_CONFIG.siteName = 'buran';

$_CONFIG.cdn = '/joxi'; // домен сервера cdn (поки один на всі проекти)
$_CONFIG.mobile = false;

// TODO id пікселя
$_CONFIG.mid = 19;

$_CONFIG.googleAnalytics = {
  appId: 'UA-90138770-1',
  trackAs: 'buran',
};

$_CONFIG.symplify = true;

$_CONFIG.places = {
  banner: {
    promo_banner: `'registration_welcome_banner'`,
  },
};

$_CONFIG.redirectToNotFound = false;
$_CONFIG.pgw = $_PGW;

$_CONFIG.scss = {
  // об'єкт, який передається в scss при компіляції
  test: 'test',
};

export { $_CONFIG };
