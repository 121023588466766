const router = [
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk></linda-app>',
  },
  {
    name: 'app.root.paymentcb2',
    url: '/payment/status?status',
    template: '<linda-payment-callback ></linda-payment-callback>',
  },
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback ></linda-payment-callback>',
  },

  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/success',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'success',
    },
  },
  {
    name: 'app.root.withdraw-paymentcb-fail',
    url: '/withdraw-payment/fail',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'fail',
    },
  },

  // For redirecting to homepage after clicking home button in game iframe
  {
    name: 'app.root.callback',
    url: '/callback/:name',
    template: '<linda-callback-page/>',
  },

  // SIDEBAR
  {
    name: 'app.root.layer1',
    abstract: true,
    template: '<linda-view-layer-one></linda-view-layer-one>',
  },

  // <MACHINES>

  {
    name: 'app.root.layer1.claw_games',
    abstract: true,
    private: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.layer1.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer1 />',
  },
  {
    name: 'app.root.layer1.game',
    abstract: true,
    template: '<linda-game-page ></linda-game-page>',
    gamePage: true,
  },

  {
    name: 'app.root.layer1.game.real',
    url: '/play/:name',
    template: '<linda-real-game-footer game="game"></linda-real-game-footer>',
    private: true,
    params: {
      mode: 'real',
    },
  },
  {
    name: 'app.root.layer1.game.demo',
    url: '/game/:name',
    template: '<linda-demo-game-footer game="game"></linda-demo-game-footer>',
    params: {
      mode: 'demo',
    },
  },

  {
    name: 'app.root.layer1.registration-bank',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page></linda-zimpler-paynplay-page>',
  },

  //FOOTER
  {
    name: 'app.root.layer1.layer2',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>',
  },

  {
    name: 'app.root.layer1.layer2.promos',
    url: '/promotions',
    template: '<linda-promos  scroll-to-top=""></linda-promos>',
  },
  {
    name: 'app.root.layer1.layer2.promopage',
    url: '/promotions/:name',
    template: '<linda-promo-page  scroll-to-top=""></linda-promo-page>',
  },

  {
    name: 'app.root.layer1.layer2.tournaments',
    url: '/tournaments',
    template: '<linda-tournaments></linda-tournaments>',
  },
  {
    name: 'app.root.layer1.layer2.tournament',
    url: '/tournaments/:name',
    template: '<linda-tournament-page scroll-to-top=""></linda-tournament-page>',
  },

  {
    name: 'app.root.layer1.layer2.vip',
    url: '/vip',
    template: '<linda-vip></linda-vip>',
  },

  //banner
  {
    name: 'app.root.layer1.layer2.layer3',
    abstract: true,
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },

  {
    name: 'app.root.layer1.layer2.layer3.home',
    url: '/',
    template: '<linda-homepage></linda-homepage>',
  },

  //games
  {
    name: 'app.root.layer1.layer2.layer3.catalog',
    abstract: true,
    template: '<linda-casino-section></linda-casino-section>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.catalog.favourites',
    url: '/games/favourites',
    template: '<linda-game-hall-favourites></linda-game-hall-favourites>',
  },
  // check link
  {
    name: 'app.root.layer1.layer2.layer3.catalog.continue-playing',
    url: '/continue-playing',
    template: '<batman-recently-played-list1></batman-recently-played-list1>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    url: '/casino-providers/:name',
    template: '<linda-game-hall-providers></linda-game-hall-providers>',
    params: {
      pageName: 'providers',
      pageType: '',
    },
  },

  //info pages
  {
    name: 'app.root.layer1.layer2.layer3.rules',
    url: '/rules',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.privacy-policy',
    url: '/privacy-policy',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.cookies-policy',
    url: '/cookies-policy',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.about',
    url: '/about',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.faq',
    url: '/faq',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },

  {
    name: 'app.root.layer1.layer2.layer3.contacts',
    url: '/contact-us',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },

  {
    name: 'app.root.layer1.layer2.layer3.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap scroll-to-top=""></linda-sitemap>',
  },

  {
    name: 'app.root.layer1.layer2.layer3.not-found',
    url: '/404',
    template: '<not-found ></not-found>',
  },

  {
    name: 'app.root.missed-data',
    url: '/missed-data',
    template: '<linda-missed-data-form></linda-missed-data-form>',
  },

  {
    name: 'app.root.layer1.layer2.verification',
    url: '/account-verification',
    template: '<dvs-page  scroll-to-top=""></dvs-page>',
    private: true,
  },
  {
    name: 'app.root.summer',
    url: '/seasons/summer',
    private: true,
    template: '<summer-promo-wrapper scroll-to-top="" ></summer-promo-wrapper>',
  },
  {
    name: 'app.root.summer-game',
    url: '/seasons-summer',
    private: true,
    template: `<summer-promo-page scroll-to-top=""></summer-promo-page>`,
  },
  // {
  //   name: 'app.root.layer1.layer2.seasons',
  //   url: '/seasons/:name',
  //   template: `<easter-promo scroll-to-top=""></easter-promo>`,
  // },

  {
    name: 'app.root.layer1.layer2.layer3.hall',
    url: '/:collection',
    template: `<batman-game-hall1 scroll-to-top></batman-game-hall1>`,
    collection: 'games',
    onEnter: ['casinoResolver', function(casinoResolver) {
      casinoResolver.collection();
    }]
  },
  {
    name: 'app.root.layer1.layer2.layer3.catalog.collection',
    url: '/:collection/:category',
    template: '<linda-game-hall></linda-game-hall>',
    params: {
      collection: 'games',
      category: 'top',
    },
    onEnter: ['casinoResolver', function(casinoResolver) {
      casinoResolver.collection();
    }]
  },
];

export { router };
