import '../../../service/domain/vip.js';
(function () {
  'use strict';

  const component = { name: 'lindaProfileVip' };

  controller.$inject = ['$scope', 'vip'];

  function controller($scope, _vip) {
    $scope.collection = [];
    $scope.preloader = false;

    $scope.preloader = true;
    _vip.collection().then(
      function (answer) {
        $scope.collection = answer.result;
        $scope.preloader = false;
      },
      function (answer) {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
