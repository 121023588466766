const aliases = {
  home: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.home',
  },
  promo: {
    page: true,
    name: 'app.root.layer1.layer2.promos',
  },
  login: {
    page: false,
    name: 'login',
  },
  registration: {
    page: false,
    name: 'registration',
  },
  paynplay: {
    page: true,
    name: 'app.root.layer1.registration-bank',
  },
  deposit: {
    page: false,
    name: 'profile',
    content: { tab1: 1, tab2: 0 },
    private: true,
  },
  withdrawal: {
    page: false,
    name: 'profile',
    content: { tab1: 3, tab2: 1 },
    private: true,
  },
  history: {
    page: false,
    name: 'profile',
    content: { tab1: 3, tab2: 'transactionHistory' },
    private: true,
  },
  quick_deposit_new: {
    page: false,
    name: 'profile',
    content: {
      tab1: 1,
      tab2: 0,
      place: 'quick_deposit_new',
    },
    private: true,
  },
  realGame: {
    page: true,
    name: 'app.root.layer1.game.real',
  },
  notFound: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.not-found',
  },

  missedData: {
    page: true,
    name: 'app.root.missed-data',
  },
  profile: {
    page: false,
    name: 'profile',
    content: { tab1: 0, tab2: 0 },
    private: true,
  },
  bonuses: {
    page: false,
    name: 'profile',
    content: { tab1: 2, tab2: 0 },
    private: true,
  },
  bonus: {
    page: false,
    name: 'profile',
    content: { tab1: 2, tab2: 0 },
    private: true,
  },
  terms: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.rules',
  },
  oktoberfest: {
    page: true,
    name: 'app.root.layer1.claw_games.gamepage',
    params: {
      name: 'oktoberfest',
    },
  },
  bonus_crab: {
    page: true,
    name: 'app.root.layer1.claw_games.gamepage',
    params: { name: 'cash-crab' },
  },
  cash_crab: {
    page: true,
    name: 'app.root.layer1.claw_games.gamepage',
    params: { name: 'cash_crab' },
  },
  claw: {
    page: true,
    name: 'app.root.layer1.claw_games.gamepage',
  },
  gameProvider: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
  },
  pragmatic: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'pragmatic',
    },
  },
  threeoaks: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'threeoaks',
    },
  },
  spinomenal: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'spinomenal',
    },
  },
  ela: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'ela',
    },
  },
  playngo: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'playngo',
    },
  },
  relax: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'relax',
    },
  },
  skywind: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'skywind',
    },
  },
  netent: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'netent',
    },
  },
  pushgaming: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'pushgaming',
    },
  },
  playtech: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'playtech',
    },
  },
  quickspin: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'quickspin',
    },
  },
  redtiger: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'redtiger',
    },
  },
  evolution: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'evolution',
    },
  },
  halloween: {
    page: true,
    name: 'app.root.layer1.layer2.tournament',
    params: {
      name: 'halloween',
    },
  },
  tournaments: {
    page: true,
    name: 'app.root.layer1.layer2.tournaments',
  },
  christmasTournament: {
    page: true,
    name: 'app.root.layer1.layer2.tournament',
    params: {
      name: 'party-raffle',
    },
  },
  promotions: {
    page: true,
    name: 'app.root.layer1.layer2.promos',
  },
  promos: {
    page: true,
    name: 'app.root.layer1.layer2.promopage',
  },
  contact: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.contacts',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },
  games: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.hall',
    params: {
      collection: 'games',
    },
  },
  live: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.hall',
    params: {
      collection: 'live-casino',
    },
  },
  dropwins: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.collection',
    params: {
      collection: 'games',
      category: 'dropwins',
    },
  },
  summer: {
    page: true,
    name: 'app.root.summer',
  },
  summerGame: {
    page: true,
    name: 'app.root.summer-game',
  },
  christmasPromotion: {
    page: true,
    name: 'app.root.layer1.layer2.promopage',
    params: {
      name: 'million-frostival-spins',
    },
  },
  christmasPromotionRaffle: {
    page: true,
    name: 'app.root.layer1.layer2.promopage',
    params: {
      name: 'frostival-prizes',
    },
  },
};

export { aliases };
