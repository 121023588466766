import '../../service/domain/game.js';

(function () {
  'use strict';

  const component = { name: 'lindaGameLists' };

  controller.$inject = ['$scope', 'game', '$timeout'];

  function controller($scope, _game, $timeout) {
    $scope.collection = [];
    $scope.isFav = false;
    $scope.gameCategory = 'popular';

    $scope.getGamesList = function (param) {
      $scope.isFav = param === 'favourites';
      $scope.gameCategory = param;
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
