import '../../../service/domain/game.js';
import '../../../service/rx/system$.js';
import '../../../service/helper.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaBlockFavourites' };

  const classLib = {
    full: 'linda-has-games', // клас з'являється на блоці, коли елементи є у collection
  };

  controller.$inject = ['$scope', 'game', 'system$', 'helper', '$element'];

  function controller($scope, _game, _system$, _helper, $element) {
    $scope.preloader = false;
    $scope.collection = [];

    function renew() {
      $scope.preloader = true;
      const options = {
        count: 1000,
        name: _game.favourite.collection().join(','),
      };

      _helper.game.collection.apply($scope, [
        options,
        (answer) => {
          $element[0].classList.toggle(classLib.full, answer.length > 0);
        },
      ]);
    }

    renew();

    _system$
      .pipe(filter((m) => m.action === 'renew favourite'))
      .subscribe((m) => {
        renew();
      });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
